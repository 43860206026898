@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

/* colors */
$primary: #F05336;
$dark: #000000;
$light: #ffffff;

/* Input */
$input-padding-x: 10px;
$input-padding-y: 10px;
$input-group-addon-bg: $light;
$input-border-color: $gray-200;
$input-focus-border-color: $gray-200;

/* inputgroup adon */
$input-group-addon-color: $gray-600;
$input-group-addon-padding-x: 15px;

/* input check */
$border-radius-base: 0 !default;
$form-check-input-border-radius: $border-radius-base;
$form-check-input-border: 2px solid rgba($black, 1);
$input-focus-border-color: $black;
$form-select-focus-box-shadow: none;


/* Button */
$input-btn-padding-y: 10px;
$input-btn-padding-x: 25px;
$btn-border-radius: 0px;
$input-btn-focus-box-shadow: none;
$input-btn-font-size: 16px;


/* border */

$btn-border-radius-base: $border-radius-base !default;


/* NavBar */
$navbar-light-color: $dark;
$navbar-nav-link-padding-x: 1rem;
$navbar-padding-y: 1.5rem;
$nav-link-font-size: 17px;

/* Nav Tabs */
$nav-tabs-link-active-color: $primary;
$nav-link-color: $dark;
$input-border-radius: 0;
$nav-tabs-border-color: none;
$nav-tabs-link-active-border-color: $light $light $primary;
$nav-tabs-link-hover-border-color: $light $light $primary ;

/* Modal */
$modal-header-border-color: none;

/* accordian */
$accordion-border-color: none;
$accordion-button-active-bg: none;
$accordion-button-active-color: $primary;
$accordion-button-padding-x: 0px;
$accordion-button-padding-y: 2px;
@import "~bootstrap/scss/bootstrap";

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px !important;
}
.mt-90{
  margin-top: 90px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.btn-primary {
  color: #ffffff;
}

.btn-primary:hover {
  color: $light;
}

body {
  margin: 0;
  color: #494949;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // ::-webkit-scrollbar {
  //   display: none;
  // }
}
body.hide-scroll {
  overflow: hidden;
  position: fixed;
}
h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.01em;
}

h2 {
  font-family: 'Exo 2', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.1em;
}

h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.6px;
}

h4 {
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.1em;
}

h5 {
  margin-bottom: 2rem;
}

.is-sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.1em;
}

.is-description {
  font-size: 15px;
  font-weight: 500;
  line-height: 190%;
  letter-spacing: 0.6px;
}

.is-carPrize {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.is-DetailValues {
    font-size: 15px;
    font-weight: 500;
    line-height: 190%;
    letter-spacing: 0.6px;
    color: darkblue;
}
.is-DetailValues-mobile {
  font-size: 13px;
  font-weight: 500;
  line-height: 190%;
  letter-spacing: 0.6px;
  color: darkblue;
}
.is-DetailFeatures {
  font-size: 22px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.5px;
}

.is-testimonials {
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.5px;
}
.is-testimonials-mobile {
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.5px;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.6px;
}

.fs-7 {
  font-weight: 300;
  font-size: 13px;
  line-height: 200%;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-14 {
  font-size: 14px !important;
}

label {
  font-size: 17px;
  font-weight: 300;
}

a {
  text-decoration: none;
}

.signin-banner {
  background-size: cover;
  background-position: center;
  height: 100vh;
}

// navbar
.navbar.navbar-light {
  box-shadow: 0px 0px 12px #d3d3d3;
}

/* Loader - 3 spinner start */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #F05336;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loader - 3 spinner end */
/* Sign banner text */
.sign-banner-text {
  position: absolute;
  top: 50%;
  left: 17%;
  transform: translateY(-50%);
  width: 25%;
}

/* Hero Sections */

.hero-section.benefit_banner,
.hero-section.who_are_we_banner,
.hero-section.car_banner,
.hero-section.why_choose_banner,
.finance_banner,
.checkout-banner {
  background-size: cover;
  background-position: center;
  padding: 80px 0;

}


.banner-class-fit {
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  background-attachment: fixed;
}

.top-banner-class-fit {
  background-size: cover;
  background-position: center;
  height: 600px;
}

.top-banner-small-fit {
  background-size: cover;
  padding: 80px 0;
}




/* home carousel */

.home-carousel {
  margin-top: 60px;
  // z-index: -1;

  .carousel-item img {
    object-fit: cover;
    object-position: top center;
    height: 94vh;
    width: 100%;
  }

  .carousel-caption {
    bottom: 32%;
    text-align: left;
    width: 40%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #1d1c1c;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    background-size: 50% 50%;
    filter: invert(1);
  }

  .carousel-indicators {
    [data-bs-target] {
      background-color: $light;
      width: 12px;
      height: 12px;
      border-radius: 50%;

    }

    .active {
      width: 24px;
      border-radius: 45%;
      background-color: $primary;
    }
  }
}

/* Card Carousel */

.card_carousel {
  .card {
    border: none;
  }

  .card-body {
    padding: 15px 0px 15px 0px;

    p {
      font-size: 14px;
      margin-bottom: 0px;
    }

    h6 {
      font-size: 15px;
    }

    h3 {
      font-size: 22px;
    }
  }
}

.popularBadge::before {
  content: " ";
  position: absolute;
  top: 0.65rem;
  left: 0.4rem;
  background: $primary;
  height: 28px;
  width: 28px;
  transform: rotate(-45deg);
  z-index: -1;
}

.badgeText {
  position: relative;
  left: 1rem;
  font-size: 13px;
  letter-spacing: 0.02em;
  z-index: 2;
  color: white;
}

.popularBadge {
  position: relative;
  width: 7.5rem;
  top: 2.6rem;
  left: -0.7rem;
  font-size: 15px;
  height: 1.5rem;
  color: #FFF;
  background: #FF0000;
}

.popularBadge:after {
  content: " ";
  position: absolute;
  display: block;
  width: 7.5rem;
  height: 1.5rem;
  top: 0.0rem;
  left: 1rem;
  z-index: 1;
  background: #FF0000;
  transform-origin: bottom left;
  -ms-transform: skew(30deg, 0deg);
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}

/* Zoom In #1 */
.is_zoom_in {
  position: relative;
}

.is_zoom_in {
  transform: scale(1);
  transition: .3s ease-in-out;
}

.is_zoom_in:hover {
  transform: scale(1.03);
}

.bottomleft {
  position: absolute;
  bottom: 10px;
  left: 25px;
}

/* location  */

.location-wrapper {
  .location-tile {
    padding: 15px;
  }

  .location-tile:hover {
    box-shadow: 1px 1px 3px #cfcfcf5c;
  }
}

/* footer  */
.footer {
  border-top: 1px solid $primary;
  padding-top: 50px;
}

/* faq */
.has-vertical-text {
  display: flex;
  align-items: center;
  height: 100%;

  .verticaltext {
    transform: rotate(-90deg);
    font-size: 80px
  }
}

.social_icons {
  color: #595f6d;
  font-size: 22px;
}

/* faq end */
/* footer  end*/

/* tab size and below screen size--------------------*/

/* Footer  below tab screen size ----------------------*/
@media all and (max-width: 990px) {
  .home-carousel {
    margin-top: 2% !important;

    .carousel-caption {
      bottom: 70%;
      transform: translateY(50%);
      width: 65%;
    }

    .carousel-item img {
      object-fit: fit;
      object-position: top center;
      height: 109vh;
      width: 100%;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      display: none;
    }
  }

  .sign-banner-text {
    left: 5%;
    width: 35%;
  }

  .footer,
  .car-details,
  .faq,
  .profile {
    .border-end-sm-0 {
      border-right: none !important;
    }
  }

  /* faq mobile */
  .has-vertical-text {
    display: block;
    align-items: center;
    height: 100%;

    .verticaltext {
      transform: none;
      font-size: 35px;
      text-align: center;
      padding-bottom: 12px;
    }
  }
}

/* tab size end */

/* Screen side medium screen and above   --------------*/
@media (min-width: 1200px) {
  .home-carousel {
    .carousel-caption {
      text-align: left;
      width: 35%;
    }
  }
  }

/* Screen side medium screen and above End  ------------*/

/*  star rating in testimonial ----------*/
.star {
  color: rgb(230, 230, 230);
}

.star.yellow {
  color: $primary;
}

/*  End of star rating ----------*/
// custom modal
.modal-95w {
  width: 97%;
  max-width: none !important;
}

.form-select:focus {
  box-shadow: 2px 5px 10px 0.2rem #f0533626;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

@media (max-width: 767px) {
  .nav-pills-scroll {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
  }

  .cntnt-width {
    width: 80%;
  }

  .testmonial-img-outer {
    text-align: end;
    padding-right: 0px;
    margin-top: 9px;
    position: absolute;
    right: 16px;
  }

  .nav-pills-scroll .nav-pills {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 8px;
    /* Add some bottom padding to accommodate the scrollbar */
  }

  .nav-pills-scroll .nav-link {
    flex: 0 0 auto;
  }

  .mob-text-align-left {
    text-align: left;
  }
  .blog-card {
  min-height: 430px !important;
  }
}

// To use has placeholder image before actual image complete loading.
.car-placeholder {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
}

.bg-secondary-light {
  background-color: $gray-200;
}

.single-item-carousel {
  width: 220px;
  margin: auto;
  overflow: hidden;
  text-align: left;
}

.single-item-carousel img {
  object-fit: cover;
  object-position: top center;
  height: 130px;
}

.single-item-carousel-detail {
  margin: auto;
  overflow: hidden;
  text-align: left;
  height: 121px;
  object-fit: contain;
  width: 85%;

}

.single-item-carousel-detail img {
  width: 100%;
  height: 100%;
}

.has-custom-arrow-carousel .react-multiple-carousel__arrow--left {
  left: calc(-2% + 1px);
}

.has-custom-arrow-carousel .react-multiple-carousel__arrow--right {
  right: calc(-2% + 1px);
}

.has-custom-arrow-carousel .react-multiple-carousel__arrow {
  background: none;
  filter: invert(1);
}

.has-popular-brand-carousel .react-multiple-carousel__arrow--left {
  left: calc(1% + 0px);
}

.has-popular-brand-carousel .react-multiple-carousel__arrow--right {
  right: calc(1% + 0px);
}

.home-type-carousel {
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    top: 25%;
  }
}
.buy-car-ad-carousel{
  z-index: -1;
  
    .react-multiple-carousel__arrow--left,
    .react-multiple-carousel__arrow--right {
      top: 25%;
    }
}

.booking-card {
  background-color: #f6f5f1;
}

.has-color-palette {
  button {
    border: none !important;
    outline: none !important;
    box-shadow: inset 0 0 4px #d6d6d6;
    border-radius: 0px;
    padding: 13px 18px;
    margin-right: 10px;
    color: #000 !important;
  }

  button svg {
    visibility: hidden;
  }

  button.active {
    svg {
      visibility: visible;

    }
  }
}

.favourite-modal {
  width: 50%;
  max-width: none !important;
}

.favourite-modal-scrollable {
  max-height: 700px;
  overflow-y: auto;
}

.custom-search {
  height: 40px;
  width: 200px;
}

.custom-search {
  position: relative;
}

.search-results {
  position: absolute;
  left: 71%;
  top: 100%;
  display: none;
  z-index: 2;
  width: 350px;
  /* Adjust the width as needed */
  max-height: 500px;
  /* Set a maximum height for scrollability */
  overflow-y: auto;
}

.search-results.active {
  display: block;
}

.search-list-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-list-li {
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: -1px;
  /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block
}

.search-list-li:hover {
  background-color: $primary;
  color: #fff;
}

//Form Stepper

.stepperConnector {
  width: 2.5rem;
  border-top: 2px solid #ddd;
  //color: $primary;
}

.stepperConnector.completed {
  border-top: 2px solid $primary;
}

.nav-link-mobile.active {
  border-bottom: 1px solid #F05336;
  color: #F05336 !important;
  background-color: transparent !important;
  padding: 0px;
  margin: 0px 10px;
}

.nav-link-mobile {
  padding: 0px;
  margin: 0px 10px;
}

.nav-link-mobile:hover {
  color: #F05336 !important;
}

.w-84 {
  width: 84%;
}

.w-26 {
  width: 26%;
}

.w-24 {
  width: 24%;
}

.w-8 {
  width: 8%;
}

.w-6 {
  width: 6%;
}

@media (max-width: 575px) {
  .dropdown-menu[data-bs-popper] {
    left: -130px;
  }
  .top-banner-class-fit {
    height: 640px;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .mobile-pt-5 {
    padding-top: 30px !important;
  }

  .custom-search {
    height: 35px;
    width: -webkit-fill-available;
  }

  .form-control,
  .form-select {
    padding: 8px !important;
    font-size: 15px !important;
  }

  .search-results {
    position: absolute;
    left: 3%;
    top: 100%;
    display: none;
    z-index: 2;
    width: 350px;
    /* Adjust the width as needed */
    max-height: 500px;
    /* Set a maximum height for scrollability */
    overflow-y: auto;
  }

  .mobile-search-icon {
    margin-left: 7em !important;
  }
  .head-text{
    font-size: 17px !important;
  }
}


.horizontal-scrollable>.row {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
  display: inline-block;
  float: none;
}

.chatbot {
  width: 55px;
  height: 55px;
  background-color: transparent;
  border: 1px solid #F05336;
  position: fixed;
  bottom: 100px;
  right: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mob-whatsapp-icon {
  width: 55px;
  height: 55px;
  background-color: #25D366;
  border: 1px solid #25D366;
  position: fixed;
  bottom: 100px;
  left: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.mob-phone-icon {
  width: 55px;
  height: 55px;
  background-color: #F05336;
  border: 1px solid #F05336;
  position: fixed;
  bottom: 25px;
  left: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.image-badge-web {
  right: 0%;
  position: absolute;
  top: 85%;
  // transform: translate(-345%, 90%);
  z-index:1;
  width:20%;
  left:0%
}

.image-badge-mobile {
  position: absolute;
  top: 78%;
  left: 0%;
  // transform: translate(0%,320%);
  z-index: 1;
}

.sale-badge {
  width: 100%;
  position: absolute;
  top: 18%;
  transform: translate(75%, -50%);
}
.assured{
  background: rgba(0, 183, 62, .231);
  // padding-top: 1px;
  border-radius: 17px;
  width: 85px;
  // border: 1px solid;
  height: 25px;
  color: #06b742;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-5 {
  padding-left: 5px;
}

.details-sale-badge {
  // position: absolute;
  // top: 11%;
  // left: -14%;
  // transform: translate(50%, -50%);
      right: 0%;
        width: 20%;
        position: absolute;
        top: 10%;
        transform: translate(12%, 0%);
        z-index:1;
}

.details-sale-badge-mobile {
       position: absolute;
    top: 0;
    right: 0;
        transform: translate(9%, 20%);
        z-index:1
}
.details-sale-badge-Bigmobile {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(10%, 20%);
  z-index: 1 
}
.details-sale-badge-tablet {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(11%, 0%);
  z-index: 1;
}

.detail-carousel-img-mobile {
  object-fit: cover !important;
  object-position: center !important;
  // height: 100px !important;
  // width: 166px !important;
}

.arrow-down {
  padding-left: 45px;
  background: #fff;
  position: relative;
}

.arrow-down.active {
  background: #fff;
}

.arrow-down:before,
.arrow-down:after {
  content: "";
  display: block;
  width: 17px;
  height: 3px;
  background: black;
  position: absolute;
  top: 20px;
  transition: transform .5s;
}

.arrow-down:before {
  right: 21px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: rotate(45deg);
}

.arrow-down:after {
  right: 10px;
  transform: rotate(-45deg);
}

.arrow-down.active:before {
  transform: rotate(-45deg);
}

.arrow-down.active:after {
  transform: rotate(45deg);
}

.breadcrumbs {
  --color-text: #5d5d5d;
  width: 100%;
  padding: 0px;
}

.breadcrumbs__list {
  list-style: none;
}

.breadcrumbs li {
  display: inline;
}

// .breadcrumbs li a {
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 19px;
//   color: grey !important;
//   text-transform: capitalize;
//   transition: color 0.24s linear;
// }

@media (hover: hover) {
  .breadcrumbs li a:hover {
    color: var(--primary-color);
  }
}

@media (max-width: 500px) {
  .home-carousel .carousel-caption {
    bottom: 70% !important;
  }

  .top-banner-class-fit {
    height: 640px;
  }

  .is-sub-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0em;
  }

  h1,
  .h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0em;
  }

  h3,
  .h3 {
    letter-spacing: 0em !important;
  }

  .ls-0 {
    letter-spacing: 0px;
  }

  .car-name,
  .car-variant {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 170% !important;
  }

  .car-details {
    font-weight: 400;
    font-size: 12px;
    line-height: 0%;
  }

  .card-img,
  .card-img-top {
    border-top-right-radius: 0px;
  }

  .benefits-carousel {
    min-height: 300px !important;
  }
}

@media (max-width: 380px) {
  .home-carousel .carousel-caption {
    bottom: 68% !important;
  }

  .top-banner-class-fit {
    height: 640px;
  }

  .fs-16 {
    font-size: 16px !important;
  }
  .card-outer{
    height: 192px !important;
  }
  .mobile-card-image-outer{
    height: 192px !important;
  }

}

.form-control,
.form-select {
  padding: 10px 30px 10 px 10px !important;
  font-size: 15px !important;
}

.location-search .form-select {
  padding: 11px 30px 11px 10px !important;
  font-size: 15px !important;
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}

.mobile-card-image-outer {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.web-card-image-outer {
  position: relative;
  display: inline-block;
}

// .header-visible {
//   height: 30px !important;
//   transform: translateY(100);
//   /* Keep the header visible */
//   transition: transform 0.1s ease-in-out;
//   /* Add a smooth transition effect */
// }

// .header-hidden {
//   transform: translateY(-100%);
//   z-index: 1;
//   /* Hide the header above the viewport */
//   transition: transform 0.1s ease-in-out;
//   /* Add a smooth transition effect */
// }

.mob-padd {
  padding: auto;
}

.testmonial-outer {
  border: 0.5px solid #c3c3c3fa;
  margin-top: 15px;
  padding-top: 20px;
  border-radius: 15px;
}

.testmonial-img-outer {
  text-align: end;
  padding-right: 0px;
}

.testimonial-text-padding {
  padding-right: 7px !important;
  padding-left: 7px !important;

}

.filter-column,
.content-column {
  height: 70vh;
  overflow-y: auto;
}

.scrollable-filter,
.scrollable-content {
  height: 100%;
  padding: 10px;
}

.breadcrumb-text a {
  color: grey !important;

}

.image-container {
  display: inline-block;
  /* Ensures the container only takes the necessary space */
  position: relative;
}

.image {
  /* Add any styles you need for your image */
  max-width: 100%;
  /* To ensure the image fits within its container */
  display: block;
}

.pointer {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #F05336;
  /* Change this to the color you want */
  border-radius: 50%;
  /* Makes the div round */
  color: white;
  text-align: center;
  font-size: 16px;
  /* Use the top and left properties to position the pointer */
  cursor: pointer;
  /* Add this to change cursor on hover */
}

.pointer.active::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  border: 2px solid #F05336;
  /* Color of the concentric ring */
  border-radius: 50%;
  top: -5px;
  /* Adjust position to make it concentric */
  left: -5px;
  /* Adjust position to make it concentric */
  box-sizing: border-box;
}


.dent-preview {
  z-index: 2;
  // width: 350px;
  // height: 350px;
  // position: relative;
  object-fit: contain;
}


.btn:hover {
  color: #fff;
}

.comparsion-img-out {
  width: 20%;
}

.w-80 {
  width: 80% !important;
}

.card-header {
  background-color: blue;
  color: white;
}

/* Active state */
.card-header.collapsed {
  background-color: green;
  color: white;
}

.head .accordion-button {
  background-color: $primary;
  color: #fff;
  padding: 5px;
  font-size: 25px;
  padding-left: 19px;
}

.fs-48 {
  font-size: 48px;
}

.fs-30 {
  font-size: 30px;
}

.fs-25 {
  font-size: 25px;
}

.add-comparison {
  height: 133px;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  color: #fff !important;
}

.social-media-icons .btn:hover {
  color: $primary !important;
}

.quick-links a:hover {
  color: $primary !important;
}

.rounded-top-15 {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.rounded-start-15 {
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
}


// 
.accordion-button::after {
  height: 20px;
  width: 21px;
  background-color: #fff;
  border-radius: 100%;

}

.round-button {
  border-radius: 25% !important;
  /* Make it round */
  font-size: 8px;
  /* Adjust font size */
}

.deal {
  font-size: 25px !important;
}

.ribbon {
  position: relative;
  background-size: cover;
  text-transform: uppercase;
  color: white;

}

.ribbon-detail {
  position: absolute;
  top: -6.1px;
  right: 25px;
}

.ribbon1 {
  position: absolute;
  top: -6.1px;
  left: 15px;
}


.ribbon1:after,
.ribbon-detail:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 32px solid transparent;
  border-right: 32px solid transparent;
  border-top: 10px solid #F8463F;
  bottom: -9px;
  z-index: 2;
}

.ribbon1 span,
.ribbon-detail span {
  position: relative;
  display: block;
  text-align: center;
  background: #F8463F;
  font-size: 12px !important;
  line-height: 1;
  padding: 12px 8px 10px;
  border-top-right-radius: 8px;
  width: 65px;
  z-index: 2;
  font-weight: 600 !important;
}

.ribbon1 span:before,
.ribbon1 span:after,
.ribbon-detail span:before,
.ribbon-detail span:after {
  position: absolute;
  content: "";
}

.ribbon1 span:before,
.ribbon-detail span:before {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #F8463F;
}

.ribbon1 span:after,
.ribbon-detail span:after {
  height: 6px;
  width: 8px;
  left: -8px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #C02031;
}

@media (max-width: 575px) {
  .ribbon1:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 26px solid transparent;
    border-right: 24px solid transparent;
    border-top: 10px solid #F8463F;
    z-index: 2;
    left: -5px;
  }

  .ribbon1 span {
    position: relative;
    display: block;
    text-align: center;
    background: #F8463F;
    font-size: 10px !important;
    line-height: 1;
    padding: 15px 5px 10px;
    border-top-right-radius: 8px;
    width: 50px;
    z-index: 2;
    font-weight: 600 !important;
    right: 5px;
  }

  .ribbon1 {
    position: absolute;
    top: -6.1px;
    left: 25px;
  }
}

.link-pointer {
  cursor: pointer;
}

.p-2-custom {
  padding: 6px !important;
}

.offcanvas-header .btn:hover {
  color: $primary !important;
}

#useScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#useScroll::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

#useScroll::-webkit-scrollbar-thumb {
  background-color: #9191919d;
  cursor: pointer;
}

#useScroll {
  cursor: pointer;

}
.searchSticky{
  position: sticky !important;
    top: 120px !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.react-multi-carousel-dot--active button {
  background: #fc3427 !important;
}

// .react-multi-carousel-dot button {
//   border-width: 2px;
//   border-style: solid;
//   border-color: #fc3427 !important;
// }

.sticky-wrapper {
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 0px 12px #d3d3d3;
  left: 0;
}
.sticky-compare {
  position: fixed;
  top: 106px;
  background-color: white;
  z-index: 100;
  left: 23px;
  width: 100%;
}

.faq-question {
  font-weight: bolder;
}

.faq-answer {
  font-weight: bold;
}

.head-bold {
  font-weight: bold;
}

.desc-bold {
  font-weight: bold;
}

.mob-test-center {
  text-align: center;

}

.accordion-border-padding {
  padding: 0px 12px !important;
}
.otp-resend-btn .btn:hover{
  color: #fff!important;
}
.comparsion-img-mob-out{
  width: 33%;
}

// detail page modal css starts here

.sidebar-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width:44%; /* Adjust the width as needed */
  background: #ffffff; /* Modal background color */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); /* Box shadow */
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  z-index: 10000; /* Ensure it appears above other elements */
}

.sidebar-modal.open {
  transform: translateX(0); /* Move modal into view */
}

.modal-content {
  padding: 20px; /* Add padding inside the modal */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.fs-18{
  font-size: 18px;
}
.w-79{
  width: 81%;
}
.w-19{
  width: 19%;
}
.h-70{
  height: 70px;
}
.h-85{
  height: 85px;
}
.head-text{
  colstyleor: #f05336;
  font-weight: 300;
  font-size: 23px;
}

.scrol-cnt, .scroll-side-cnt{
  overflow-y: scroll;
  height: 800px;
}

// .scroll-side-cnt{
//   overflow-y: scroll;
//   height: 700px;
// }
.scrol-cnt::-webkit-scrollbar,
.scroll-side-cnt::-webkit-scrollbar{
  width: 0 !important;
}
.modal-outer{
  height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 62%);
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 8; */
    z-index: 100000;
}
 .featuer-modal .modal-dialog{
  margin: 0 0 0 auto !important;
}
.featuer-modal .modal-content {
  border: 0px;
  border-radius: 0px;
  height: 100vh;
  padding: 0px;
}
.featuer-modal .modal-body {
  padding: 12px;
}


.md-btn {
font-size: 15px;
  padding: 7px 23px;
}


.card-outer{
  height: 192px;
}

.blog-card {
  background-color: #fff;
  width: 100%;
  min-height: 510px;
  border-radius: 8px !important;
  border: 1px solid #817f7f3a !important;
  transition: all 0.25s !important;

}

.blog-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2) !important;
  

}
.blog-image {
  position: relative;
}

.card-image {
  transition: opacity 0.3s ease-in-out;
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(65, 64, 64, 0.24);
 
  transition: opacity 0.3s ease-in-out;
}

.blog-card:hover .image-overlay {
  opacity: 0;

}


// .pagination {
//   border: none !important;

// }

// .page-item {
//   border: none !important;

// }
.custom-dent .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  color: $primary !important;
  background-color: transparent !important;
}
.custom-dent .nav-link{
  color: #6c757d !important;
}

.benefits-carousel{
  background-color: #f9f9f9;
    min-height: 190px;                            
}
.width-c{
  width: 160px !important;
}
.width-d{
  width: 215px !important;
}

.engine-accordian .accordion-button:not(.collapsed){
  color: black !important;
}

.inspection-note {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.inspection-note hr {
  margin-left: 10px;
  flex: 1;
}

@media all and (max-width:820px) {

  .search-results {
    left: auto;
    width: 92%;
  }

  .mt-lg-120 {
    margin-top: 120px !important;
  }
}

.custom-dot-button-for-card {
  margin: 2px;
  padding: 2px;
  border-radius: 50px;
  width: 12px;
  height: 12px;
}

.custom-dot-button-for-card.active{
background-color: $primary;
width: 13px;
height: 13px;
}

 

.profile-nav-scroll {
  height: 100vh;
  overflow-y: auto;
}

.profile-nav-scroll::-webkit-scrollbar {
  display: none; 
}
.toll-free-container {
  display: flex;
  justify-content: flex-start;
 


  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: $primary;
  height: 35px;
  width: 320px;
}

.toll-free-number {
  margin-left: 10px;

  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.bg-primary{
  background-color: $primary;
}
.text-white{
  color: #fff;
}

.search-filter{
  display: none;
  max-height: 200px;
  overflow-y: auto;
}
.search-filter.active {
   display: block;
 }
 @media (min-width: 768px) and (max-width: 991.98px) {
  .container-fluid-tablet {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
    .sticky-compare {
      position: fixed;
      top: 0px;
      background-color: white;
      z-index: 100;
      left: 23px;
      width: 100%;
    }

  
}
/* iPad and other tablets */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .searchSticky {
    position: sticky !important;
    top: 70px !important;
  }
}
/* Desktops and larger screens */
@media only screen and (min-width: 1025px) {
  .searchSticky {
    position: sticky !important;
    top: 120px !important;
  }
}
.react-select__placeholder {
  /* Your styles for the placeholder */
  color: #000000 !important;
  /* Example color */

}

.inspection-details hr{
  width: 100%;
  margin-right: 20px;
  margin-left:10px;
}
.inspection-details-font{
  font-size: 14px;
}

.details-assured-badge {
  // position: absolute;
  // top: 11%;
  // left: -14%;
  // transform: translate(50%, -50%);
  right: 0%;
  width: 26%;
  position: absolute;
  top: 69%;
  transform: translate(58%, 43%);
  z-index:1;
}

.details-assured-badge-mobile {
  position: absolute;
  width: 26%;
  top: 0;
  right: 0;
  transform: translate(15%, 160%);
  z-index: 1;
  // position: absolute;
  // top: 6%;
  // left: 2%;
}
.custom-tr {
  border-style: none;
  border-color: transparent;
}


.custom-th,
.custom-td {
  padding: 5px;

}

.custom-p {
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
}

.custom-accordionheader{
      margin: 15px 0px;
      line-height: 70%;
    }

.nav-pills .nav-link.active .active-text {
  color: white !important;
}